import { InformationBox, useLanguageContext } from '../components';
import { FunctionComponent } from 'react';
import styles from './Otherpages.module.scss';

const modifiedAt = new Date('2024-11-12T21:58:59.996Z');
export const EULAPage: FunctionComponent = () => {
	const { inlineTranslation } = useLanguageContext();
	return (
		<div className={styles.page}>
			<div className={styles.container}>
				<InformationBox
					title={inlineTranslation({
						no: '1. Generelt',
						dk: '1. Generelt',
						en: '1. General',
						se: '1. Allmänt',
					})}
					icon="/images/paragraf.png"
					color="#ffc600"
					sections={[
						{
							content: inlineTranslation({
								no: (
									<ol>
										<li>
											Hundeparken er drevet av private personer, og holdes åpent så lenge vi ønsker. Spillets
											videreutvikling er avhengig av vår motivasjon til å drive videre.
										</li>
										<li>Ved å spille Hundeparken godkjenner du betingelsene som gjelder her. </li>
										<li>Hundeparken er et spill for unge voksne/voksne.</li>
										<li>
											Ved brudd på retningslinjer/brukerbetingelser vil Hundeparken vurdere hver sak som oppstår
											individuelt og handle deretter. Konsekvenser for brudd kan være permanent utestengelse.
										</li>
										<li>Det er kun tillat å ha 1 bruker i Hundeparken. Én bruker består av max 3 hunder. </li>
										<li>
											Det er ikke lov til å utgi seg for å være andre. Dette gjelder andre mennesker, andre brukere,
											moderatorer, andre ansatte og lignende.
										</li>
										<li>Behandle hverandre med respekt selv om du er uenig med dem.</li>
										<li>
											Det er ikke lov å spre falsk informasjon. Det skal ikke diskuteres enkeltpersoner eller
											enkeltsaker. Dette gjelder både baksnakking, og i forbindelse med situasjoner mellom brukere og
											moderatorer.
										</li>
										<li>
											Mobbing, spre falske rykter om andre, trakassering, rasistisk adferd, truende adferd og pedofili
											er ikke lovlig.
										</li>
										<li>
											Det er ikke tillatt å spamme i parken. Det betyr å gjenta den samme meldingen flere ganger etter
											hverandre. Det samme gjelder bjeffing og promping over lang tid.
										</li>
									</ol>
								),
								dk: (
									<ol>
										<li>
											Hundeparken drives af private personer og forbliver åben, så længe vi ønsker. Spillets
											videreudvikling afhænger af vores motivation til at fortsætte.
										</li>
										<li>Ved at spille Hundeparken accepterer du de gældende vilkår og betingelser.</li>
										<li>Hundeparken er et spil designet for unge voksne og voksne.</li>
										<li>
											Ved brud på retningslinjerne eller brugerbetingelserne vil hver sag blive vurderet individuelt, og
											der vil blive handlet derefter. Konsekvenser kan inkludere permanent udelukkelse.
										</li>
										<li>
											Det er kun tilladt at have én bruger i Hundeparken. Hver bruger må maksimalt have tre hunde.
										</li>
										<li>
											Det er forbudt at udgive sig for at være andre, herunder andre personer, brugere, moderatorer,
											ansatte eller lignende.
										</li>
										<li>Vis altid respekt for hinanden, selv i tilfælde af uenighed.</li>
										<li>
											Det er ikke tilladt at sprede falsk information eller diskutere enkeltpersoner og specifikke
											sager. Dette omfatter både bagtalelse og konflikter mellem brugere og moderatorer.
										</li>
										<li>
											Mobning, spredning af falske rygter, chikane, racistisk adfærd, truende adfærd og pædofili er
											strengt forbudt.
										</li>
										<li>
											Det er ikke tilladt at spamme i Parken. Det vil sige, at du ikke må gentage samme besked flere
											gange i træk. Det samme gælder at gø og prutte flere gange i en længere periode.
										</li>
									</ol>
								),
								en: (
									<ol>
										<li>
											Hundeparken is running by private individuals and is kept open for as long as we want. The further
											development of the game depends on our motivation to drive it.
										</li>
										<li>By playing Hundeparken you agree to the terms that apply here.</li>
										<li>Hundeparken is a game for young adults/adults.</li>
										<li>
											In case of violation of guidelines/terms of use, Hundeparken will assess each individual case that
											arises and act accordingly. Consequences for violations may be permanently banned.
										</li>
										<li>It is only allowed to have 1 user in Hundeparken. A user consists of a maximum of 3 dogs.</li>
										<li>
											It is not allowed to pretend to be someone else. This includes other people, other users,
											moderators, other employees and the like.
										</li>
										<li>Treat others with respect even if you don’t agree with them.</li>
										<li>
											It is not allowed to spread false information. Individuals or individual cases shall not be
											discussed. This applies both in case of defamation, and in connection with situations between
											users and moderators.
										</li>
										<li>
											Bullying, spreading false rumors about others, harassment, racist behavior, threatening behavior
											and pedophilia are not legal.
										</li>
										<li>
											Spamming is not allowed in the park. It means repeating the same message several times in a row.
											The same applies to barking and farting for a long time.
										</li>
									</ol>
								),
								se: (
									<ol>
										<li>
											Hundeparken drivs av privatpersoner och hålls öppen så länge vi vill. Spelets vidareutveckling
											beror på vår motivation att driva det.
										</li>
										<li>Genom att spela Hundeparken godkänner du de villkor som gäller här.</li>
										<li>Hundeparken är ett spel för unga vuxna/vuxna.</li>
										<li>
											Vid överträdelse av riktlinjer/användarvillkor kommer Hundeparken att bedöma varje enskilt fall
											som uppstår och agera därefter. Konsekvenser för överträdelser kan vara permanent uteslutning.
										</li>
										<li>
											Det är endast tillåtet att ha 1 användare i Hundeparken. En användare består av max 3 hundar.
										</li>
										<li>
											Det är inte tillåtet att låtsas vara andra. Detta gäller andra personer, andra användare,
											moderatorer, andra anställda och liknande.
										</li>
										<li>Behandla varandra med respekt även om du inte håller med dem.</li>
										<li>
											Det är inte tillåtet att sprida falsk information. Enskilda personer eller enskilda fall skall
											inte diskuteras. Detta gäller både vid förtal, och i samband med situationer mellan användare och
											moderatorer.
										</li>
										<li>
											Mobbning, att sprida falska rykten om andra, trakasserier, rasistiskt beteende, hotfullt beteende
											och pedofili är inte lagligt.
										</li>
										<li>
											Det är inte tillåtet att spamma i parken. Det betyder att upprepa samma meddelande flera gånger
											efter varandra. Samma sak gäller skällande och pruttande under en längre stund.
										</li>
									</ol>
								),
							}),
						},
					]}
				/>
				<InformationBox
					title={inlineTranslation({
						no: '2. Rettigheter',
						dk: '2. Rettigheder',
						en: '2. Rights',
						se: '2. Rättigheter',
					})}
					color="#ffc600"
					sections={[
						{
							content: inlineTranslation({
								no: (
									<ol>
										<li>
											Dine personlige opplysninger er beskyttet og vil ikke bli gitt videre til en tredjepart med mindre
											det er påkrevd fra politiet i forbindelse med kriminelle saker.
										</li>
										<li>Personlige opplysninger som er lagret om deg er din e-post og IP adresse.</li>
										<li>Hundeparken eier alle rettighetene til grafikk, og alt som tilhører plattformen. </li>
									</ol>
								),
								dk: (
									<ol>
										<li>
											Dine personlige oplysninger er beskyttet og videregives kun til tredjeparter, hvis det er påkrævet
											af politiet i forbindelse med en kriminalsag.
										</li>
										<li>De personlige oplysninger, vi opbevarer om dig, er din e-mailadresse og IP-adresse.</li>
										<li>Hundeparken har alle rettigheder til grafikken og alt indhold, der tilhører platformen.</li>
									</ol>
								),
								en: (
									<ol>
										<li>
											Your personal information is protected and will not be forwarded to a third party if it’s not
											required by law enforcement agencies in connection criminal proceedings.
										</li>
										<li>Personal data stored about you is your e-mail address and IP address.</li>
										<li>Hundeparken owns all rights to graphics, and everything that belongs to the platform.</li>
									</ol>
								),
								se: (
									<ol>
										<li>
											Din personliga information är skyddad och kommer inte att vidarebefordras till en tredje part om
											det inte krävs av brottsbekämpande myndigheter i samband med straffrättsliga förfaranden.
										</li>
										<li>Personuppgifter som lagras om dig är din e-postadress och IP-adress.</li>
										<li>Hundeparken äger alla rättigheter till grafik, och allt som hör plattformen till.</li>
									</ol>
								),
							}),
						},
					]}
				/>
				<InformationBox
					title={inlineTranslation({
						no: '3. Drift og utvikling',
						dk: '3. Drift og udvikling',
						en: '3. Operation and development',
						se: '3. Drift och utveckling',
					})}
					color="#ffc600"
					sections={[
						{
							content: inlineTranslation({
								no: (
									<ol>
										<li>
											Hundeparken er et spill i utvikling, og tekniske problemer kan forekomme. Dersom du opplever feil
											oppfordres du til å sende e-post til mail@hundeparken.net.
										</li>
										<li>
											Ved endringer kan det forekomme feil som gjør at funksjoner blir inaktive eller fjernet. Dette kan
											være midlertidig eller permanent.
										</li>
										<li>
											Hundeparken påtar seg ikke ansvaret for feil med brukere, men vil alltid etterstrebe å
											tilbakestille eller rette opp i feilen ved tekniske problemer.
										</li>
									</ol>
								),
								dk: (
									<ol>
										<li>
											Hundeparken er et spil under udvikling, og tekniske problemer kan forekomme. Hvis du oplever fejl,
											bedes du sende en e-mail til mail@hundeparken.net.
										</li>
										<li>
											Ændringer kan medføre midlertidige eller permanente fejl, der kan gøre funktioner inaktive eller
											fjerne dem.
										</li>
										<li>
											Hundeparken påtager sig ikke ansvaret for brugerrelaterede fejl, men vil altid bestræbe sig på at
											genoprette eller rette tekniske problemer.
										</li>
									</ol>
								),
								en: (
									<ol>
										<li>
											Hundeparken is a game in development, and technical difficulties may occur. If you experiences
											errors, you are requested to send an e-mail to mail@hundeparken.net.
										</li>
										<li>
											Changes may cause errors that cause features to become inactive or removed. This can be temporary
											or permanent.
										</li>
										<li>
											Hundeparken take no responsebility for errors by users, but will always strive to restore or fix
											the error in the event of technical problems.
										</li>
									</ol>
								),
								se: (
									<ol>
										<li>
											Hundeparken är ett spel under utveckling, och tekniska problem kan uppstå. Om du upplever fel
											uppmanas du att skicka ett e-postmeddelande till mail@hundeparken.net.
										</li>
										<li>
											Ändringar kan orsaka fel som gör att funktioner blir inaktiva eller tas bort. Detta kan vara
											tillfälligt eller permanent.
										</li>
										<li>
											Hundeparken tar inget ansvar för fel hos användare, utan kommer alltid att sträva efter att
											återställa eller åtgärda felet vid tekniska problem.
										</li>
									</ol>
								),
							}),
						},
					]}
				/>
				<InformationBox
					title={inlineTranslation({
						no: '4. Sletting av din konto',
						dk: '4. Sletning af din konto',
						en: '4. Delete user account',
						se: '4. Radering av ditt konto',
					})}
					color="#ffc600"
					sections={[
						{
							content: inlineTranslation({
								no: (
									<ol>
										<li>
											Dersom du ønsker at din bruker blir slettet fra Hundeparken må du sende e-post til
											mail@hundeparken.net med e-posten din bruker er registrert på. Det kan gå opp til 2 uker etter du
											har sendt forespørsel, før din forespørsel blir behandlet.
										</li>
										<li>
											Når en konto blir slettet blir all tilhørende informasjon fjernet. Følgende informasjonen blir
											slettet: Hundene dine, brukeren din og alt som tilhører hunden i spillet. I tillegg vil din e-post
											og IP adresse bli fjernet.
										</li>
									</ol>
								),
								dk: (
									<ol>
										<li>
											Hvis du ønsker at få din bruger slettet fra Hundeparken, skal du sende en e-mail til
											mail@hundeparken.net fra den e-mailadresse, din bruger er registreret med. Det kan tage op til 2
											uger at behandle din anmodning.
										</li>
										<li>
											Ved sletning af en konto fjernes alle tilhørende oplysninger, herunder dine hunde, din bruger og
											alt, der tilhører hunden i spillet. Din e-mailadresse og IP-adresse vil også blive fjernet.
										</li>
									</ol>
								),
								en: (
									<ol>
										<li>
											If you want us to delete your user account from Hundeparken, you need to send us an e-mail at
											mail@hundeparken.net with your registered e-mailadress. It may take up to 2 weeks after you sent
											your request for your request to be processed.
										</li>
										<li>
											When an account is deleted, all associated information is deleted. The following information will
											be deleted: Your dogs, your user and everything belonging to the dog in the game. In addition,
											your email address and IP address will be removed.
										</li>
									</ol>
								),
								se: (
									<ol>
										<li>
											Om du vill att din användare ska raderas från Hundeparken behöver du skicka ett e-postmeddelande
											till mail@hundeparken.net med den e-postadress som din användare är registrerad på. Det kan ta upp
											till 2 veckor efter att du har skickat in din begäran, innan din begäran behandlas.
										</li>
										<li>
											När ett konto tas bort tas all associerad information bort. Följande information kommer att
											raderas: Dina hundar, din användare och allt som tillhör hunden i spelet. Dessutom kommer din
											e-postadress och IP-adress att tas bort.
										</li>
									</ol>
								),
							}),
						},
					]}
				/>
				<InformationBox
					title={inlineTranslation({
						no: '5. Hundeparken som chat',
						dk: '5. Hundeparken som chat',
						en: '5. Hundeparken as a chat',
						se: '5. Hundparken som en chatt',
					})}
					color="#ffc600"
					sections={[
						{
							content: inlineTranslation({
								no: (
									<ol>
										<li>
											Hundeparken er en chat for unge voksne/voksne hvor det kan forekomme temaer som for noen kan føles
											grenseoverskridende.
										</li>
										<li>
											Man er selv ansvarlig for å forlate rommet dersom temaer som prates om føles triggende. Det er en
											selv som kjenner sine egne begrensninger.
										</li>
										<li>
											Hundeparken forbeholder seg retten til å utestenge eller slette din bruker uten forvarsel ved
											uakseptabel oppførsel, forsøk på juks eller brudd på brukerbetingelsene.
										</li>
										<li>
											Doxxing, altså å utgi andres personlig informasjon som de selv ikke har delt i parken er ikke lov.{' '}
										</li>
										<li>
											Når man spiller Hundeparken skal man følge Svensk, Dansk og Norsk lovverk, inkludert straffelovens
											regler om trusler, barnepornografi og rusmidler.
										</li>
									</ol>
								),
								dk: (
									<ol>
										<li>
											Hundeparken er en chat for unge voksne/voksne, hvor der kan diskuteres emner, som nogle måske
											finder grænseoverskridende. Det er dit eget ansvar at forlade rummet, hvis du finder emnerne uden
											for dine grænser. Man kender bedst selv sine egne grænser.
										</li>
										<li>
											Hundeparken forbeholder sig retten til at udelukke eller slette din bruger uden forudgående varsel
											ved uacceptabel opførsel, forsøg på snyd eller brud på betingelserne.
										</li>
										<li>Doxxing, dvs. at dele andres personlige oplysninger uden deres samtykke, er forbudt.</li>
										<li>
											Når du bruger Hundeparken, skal du overholde svensk, dansk og norsk lovgivning, herunder regler om
											trusler, børnepornografi og rusmidler.
										</li>
									</ol>
								),
								en: (
									<ol>
										<li>
											Hundparken is a chat for young adults/adults where there may be topics that some may feel crosses
											the line. You are responsible for leaving the room if you feel triggered to talk about that topic.
											You know your own limitations.
										</li>
										<li>
											Hundeparken reserves the right to suspend or delete your user without warning in case of
											unacceptable behavior, attempted cheating or violation of the terms of use.
										</li>
										<li>
											Doxxing, i.e. giving out other people's personal information that they themselves have not shared
											in the park, is not allowed.
										</li>
										<li>
											When you play Hundeparken, you must comply with Swedish, Danish and Norwegian legislation,
											including the penal code's rules on threats, child pornography and drugs.
										</li>
									</ol>
								),
								se: (
									<ol>
										<li>
											Hundparken är en chatt för unga vuxna/vuxna där det kan finnas ämnen som för vissa kan kännas
											gränsöverskridande. Du ansvarar själv för att lämna rummet om det känns triggande att prata om det
											ämnet. Det är en själv som känner sina egna begränsningar.
										</li>
										<li>
											Hundeparken förbehåller sig rätten att utan förvarning stänga av eller radera din användare vid
											oacceptabelt beteende, försök till fusk eller brott mot användarvillkoren.
										</li>
										<li>
											Doxxing, det vill säga att lämna ut andras personuppgifter som de själva inte har delat med sig av
											i parken, är inte tillåtet.
										</li>
										<li>
											När du spelar Hundeparken måste du följa svensk, dansk och norsk lagstiftning, bland annat
											brottsbalkens regler om hot, barnpornografi och droger.
										</li>
									</ol>
								),
							}),
						},
					]}
				/>
				<InformationBox
					title={inlineTranslation({
						no: '6. Anmeldelser',
						dk: '6. Anmeldelser',
						en: '6. Reports',
						se: '6. Anmälningar',
					})}
					color="#ffc600"
					sections={[
						{
							content: inlineTranslation({
								no: (
									<ol>
										<li>
											Det er forbudt å lage falske anmeldelser.
											<br />- En falsk anmeldelse er å anmelde en annen bruker for noe vedkommende ikke har gjort.
										</li>
										<li>Det er forbudt å lyve i anmeldelser.</li>
										<li>Det er forbudt å lage useriøse anmeldelser.</li>
									</ol>
								),
								dk: (
									<ol>
										<li>
											Det er ikke tilladt at lave falske anmeldelser, herunder anmeldelser der uberettiget anklager en
											bruger for handlinger, de ikke har begået.
										</li>
										<li>Det er ikke tilladt at lyve i sin anmeldelse.</li>
										<li>Useriøse anmeldelser er forbudte.</li>
									</ol>
								),
								en: (
									<ol>
										<li>
											Creating false reports is prohibited.
											<br />- A false report is reporting another user for something they did not do.
										</li>
										<li>It is prohibited to lie in a report.</li>
										<li>It is prohibited to do irresponsible reports.</li>
									</ol>
								),
								se: (
									<ol>
										<li>
											Det är förbjudet att skapa falska anmälningar.
											<br />
											-En falsk anmälan är att anmäla en annan användare för något de inte har gjort.
										</li>
										<li>Det är förbjudet att ljuga i anmälningar.</li>
										<li>Det är förbjudet att göra oseriösa anmälningar.</li>
									</ol>
								),
							}),
						},
					]}
				/>
				<InformationBox
					title={inlineTranslation({
						no: '7. Kjøp og salg',
						dk: '7. Køb og salg',
						en: '7. Buy and sell',
						se: '7. Köp och sälja',
					})}
					color="#ffc600"
					sections={[
						{
							content: inlineTranslation({
								no: (
									<ol>
										<li>
											Det er ikke tillatt å reklamere for andre produkter og andre hjemmesider i Hundeparken. Eksempel
											på produkter som ikke er lov kan være hvis du selger produkter etter forretningsmodellen
											multi-level marketing. For eksempel: Mary Kay, Herbalife, Tupperware. Dersom du har en hjemmeside
											for din hund, kan denne deles i forumet.
										</li>
										<li>
											Det er ikke tillatt å kjøpe brukere/hunder/hatter/objekter/grafikk for penger. Ved brudd på disse
											reglene kan de involverte brukerne/hattene/objektene/grafikken bli slettet.
										</li>
									</ol>
								),
								dk: (
									<ol>
										<li>
											Reklame for eksterne produkter og hjemmesider er ikke tilladt i Hundeparken. Forbudt reklame
											omfatter produkter solgt via multi-level marketing, såsom Mary Kay, Herbalife og Tupperware. Har
											du en hjemmeside for din hund, kan du dele den i forummet.
										</li>
										<li>
											Det er ikke tilladt at købe brugere, hunde, hatte, objekter eller grafik for penge. Overtrædelser
											kan medføre sletning af de involverede brugere, hatte, objekter eller grafik.
										</li>
									</ol>
								),
								en: (
									<ol>
										<li>
											It is not permitted to advertise other products and other websites in Hundeparken: An example of
											products that are not allowed could be if you sell products according to the multi-level marketing
											business model. For example: Mary Kay, Herbalife, Tupperware.
										</li>
										<li>
											If you have an website for you dog, it can be shared in the forum. Buying
											users/dogs/hats/items/graphics for money is not allowed. In case of violation of these rules, the
											users/hats/objects/graphics involved may be deleted.
										</li>
									</ol>
								),
								se: (
									<ol>
										<li>
											Det är inte tillåtet att göra reklam för andra produkter och andra webbplatser i Hundeparken. Ett
											exempel på produkter som inte är tillåtna kan vara om du säljer produkter enligt affärsmodellen
											multi-level marketing. Till exempel: Mary Kay, Herbalife, Tupperware. Om du har en hemsida för din
											hund kan denna delas i forumet.
										</li>
										<li>
											{' '}
											Det är inte tillåtet att köpa användare/hundar/hattar/objekt/grafik för pengar. Vid brott mot
											dessa regler kan de användare/hattar/objekt/grafik som är inblandade komma att raderas.
										</li>
									</ol>
								),
							}),
						},
					]}
				/>
				<InformationBox
					title={inlineTranslation({
						no: '8. Brukerdesign',
						dk: '8. Brugerdesign',
						en: '8. Designs by users',
						se: '8. Användardesigner',
					})}
					color="#ffc600"
					sections={[
						{
							content: inlineTranslation({
								no: (
									<ol>
										<li>
											All grafikk som en bruker har overlevert til Hundeparken er brukerens opphavsrett, men gir
											Hundeparken fri bruk i evig tid fremover. Hundeparken står fritt til å endre, utvikle og justere
											hatter og objekter som brukeren har laget. Man er selv ansvarlig for at grafikken man lager til
											Hundeparken ikke bryter andres opphavsrett, og er ens eget åndsverk.
										</li>
									</ol>
								),
								dk: (
									<ol>
										<li>
											Al grafik, som en bruger har overdraget til Hundeparken, forbliver brugerens ophavsret, men
											Hundeparken har evig ret til at anvende grafikken.
										</li>
										<li>
											Hundeparken forbeholder sig retten til at ændre, udvikle og justere hatte og objekter skabt af
											brugeren.
										</li>
										<li>
											Det er brugerens ansvar at sikre, at den grafik, de laver til Hundeparken, ikke krænker andres
											ophavsret og er original.
										</li>
									</ol>
								),
								en: (
									<ol>
										<li>
											All graphics that a user has submitted to Hundeparken are the user's copyright, but give
											Hundeparken free use in perpetuity.
										</li>
										<li>Hundeparken is free to change, develop and adjust user-created hats and items.</li>
										<li>
											You are responsible for ensuring that the graphics you create for Hundeparken do not infringe on
											the copyright of others, and are your own intellectual property.
										</li>
									</ol>
								),
								se: (
									<ol>
										<li>
											All grafik som en användare har skickat in till Hundeparken är användarens upphovsrätt, men ger
											Hundeparken fri användning för all framtid.
										</li>
										<li>
											Hundparken har fritt att ändra, utveckla och justera hattar och föremål som användaren har skapat.
										</li>
										<li>
											Du ansvarar för att den grafik du skapar för Hundeparken inte gör intrång i andras upphovsrätt,
											och är din egen immateriella egendom.
										</li>
									</ol>
								),
							}),
						},
					]}
				/>
				<InformationBox
					title={inlineTranslation({
						no: '9. Juks',
						dk: '9. Snyd',
						en: '9. Cheating',
						se: '9. Fusk',
					})}
					color="#ffc600"
					sections={[
						{
							content: inlineTranslation({
								no: (
									<ol>
										<li>
											Det er ikke tillat å bruke en annen bruker til å kjøpe eller bytte hatter. Hver bruker må ha sitt
											eget individuelle og sosiale Hundepark-liv.
										</li>
										<li>Det er forbudt å jukse til seg flere karmapoeng, kjøttbein, hatter/objekter og livspoeng.</li>
										<li>
											Det er forbudt å bruke programmer som gjør at man ikke trenger å utføre oppgaver selv i spillet
											eller gir fordeler. For eksempel programmer som gjør at markøren trykker av seg selv.
										</li>
									</ol>
								),
								dk: (
									<ol>
										<li>
											Det er forbudt at anvende en anden bruger til at købe eller bytte hatte. Hver bruger skal have sit
											eget individuelle og sociale liv i Hundeparken.
										</li>
										<li>
											Det er forbudt at snyde sig til flere karmapoints, kødben, hatte, objekter eller livspoints.
										</li>
										<li>
											Programmer, der automatiserer opgaver eller giver en uretmæssig fordel, såsom selvklikende
											markører, er forbudt.
										</li>
									</ol>
								),
								en: (
									<ol>
										<li>
											It is not allowed to use another user to buy or exchange hats. Each user must have their own
											individual and social life in Hundeparken.
										</li>
										<li>Cheating for more karma points, bones, hats/items and life points is prohibited.</li>
										<li>
											It is prohibited to use programs that do not require you to perform tasks yourself in the game or
											provide benefits. For example programs that cause the cursor to be pressed by itself.
										</li>
									</ol>
								),
								se: (
									<ol>
										<li>
											Det är inte tillåtet att använda en annan användare för att köpa eller byta hattar. Varje
											användare måste ha sitt eget individuella och sociala liv i hundparken.
										</li>
										<li>Det är förbjudet att fuska för mer karmapoäng, ben, hattar/föremål och livspoäng.</li>
										<li>
											Det är förbjudet att använda program som inte kräver att du utför uppgifter själv i spelet eller
											ger fördelar. Till exempel program som gör att markören trycks av sig själv.
										</li>
									</ol>
								),
							}),
						},
					]}
				/>
				<InformationBox
					title={inlineTranslation({
						no: '10. Endringer av brukerbetingelsene',
						dk: '10. Ændringer af brugerbetingelserne',
						en: '10. Changes to the terms of use',
						se: '10. Ändringar i användarvillkoren',
					})}
					color="#ffc600"
					sections={[
						{
							content: inlineTranslation({
								no: (
									<ol>
										<li>
											Hundeparken er ikke forpliktet til å informere om endringer i brukerbetingelsene. De oppdaterte
											betingelsene vil alltid ligge tilgjengelig på denne siden.
										</li>
									</ol>
								),
								dk: (
									<ol>
										<li>
											Hundeparken er ikke forpligtet til at informere om ændringer i brugerbetingelserne. De opdaterede
											betingelser vil altid være tilgængelige på denne side.
										</li>
									</ol>
								),
								en: (
									<ol>
										<li>
											Hundeparken is not obliged to inform about changes in the terms of use. The updated terms will
											always be available on this page. You are responsible for having read through the terms of use if
											they have been updated.
										</li>
									</ol>
								),
								se: (
									<ol>
										<li>
											Hundeparken är inte skyldig att informera om ändringar i användarvillkoren. De uppdaterade
											villkoren kommer alltid att finnas tillgängliga på den här sidan. Det är du själv som ansvarar att
											ha läst igenom användarvillkoren om de uppdaterats.
										</li>
									</ol>
								),
							}),
						},
						{ content: `Opdateret ${modifiedAt.toLocaleString()}` },
					]}
				/>
			</div>
		</div>
	);
};
