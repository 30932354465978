import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export function useApp() {
	const [params, setSearchParams] = useSearchParams();

	const isApp = useMemo(() => params.get('app') === '1', []);
	const scheme = useMemo(() => params.get('appswitchUrl') || 'hundeparken', []) || 'hundeparken';
	const pathname = useMemo(() => params.get('appswitchPathname') ?? '', []);

	useEffect(() => {
		if (isApp) {
			params.delete('app');
		}

		if (params.get('appswitchUrl')) {
			params.delete('appswitchUrl');
		}

		if (params.get('appswitchPathname')) {
			params.delete('appswitchPathname');
		}

		setSearchParams(params);
	}, []);

	return {
		isApp,
		sendAppEvent: (event: string, data: object = {}) => {
			(window as any)?.ReactNativeWebView?.postMessage(JSON.stringify({ event, data }));
		},
		createAppswitchUrl: (params: Record<string, string>) => {
			const searchParams = new URLSearchParams();

			for (const [key, value] of Object.entries(params)) {
				searchParams.set(key, value);
			}

			return `${scheme}://${pathname}?${searchParams}`;
		},
	};
}
