import { FunctionComponent } from 'react';
import style from './SocialsBox.module.scss';
import { useLanguageContext } from '..';

export const SocialsBox: FunctionComponent = () => {
	const { inlineTranslation } = useLanguageContext();
	return (
		<div className={style.container}>
			<div>
				{inlineTranslation({
					dk: 'Følg os på sociale medier:',
					no: 'Følg oss på sosiale medier:',
					se: 'Följ oss på sociala medier:',
					en: 'Follow us on social media:',
				})}
			</div>
			<a href="https://www.facebook.com/hundeparken3" target="_blank" rel="noreferrer">
				<img src="images/fb_icon.png" alt="Facebook" />
			</a>
			<a href="https://www.instagram.com/hundeparken_net/" target="_blank" rel="noreferrer">
				<img src="images/insta_icon.png" alt="Instagram" />
			</a>
			<a href="https://www.youtube.com/channel/UCcElX-pdA_2q68zeKGG66jQ" target="_blank" rel="noreferrer">
				<img src="images/yt_icon.png" alt="YouTube" />
			</a>
			<a href="https://www.tiktok.com/@hundeparken" target="_blank" rel="noreferrer">
				<img src="images/tiktok_icon.png" alt="TikTok" />
			</a>
		</div>
	);
};
