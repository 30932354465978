import { FunctionComponent } from 'react';
import styles from './Otherpages.module.scss';
import highscoreStyles from './HighscorePage.module.scss';
import { Link, useParams } from 'react-router-dom';
import { HighscoreInterval, MiniGame } from '../graphql-types';
import classNames from 'classnames';
import { HighscorePanel } from '../components';

const games = Object.values(MiniGame).reverse();

export const HighscorePage: FunctionComponent = () => {
	const { game } = useParams();

	const selectedGame = (game ?? MiniGame.KnogleKnas) as MiniGame;
	return (
		<div className={styles.page}>
			<div className={styles.container}>
				<div className={highscoreStyles.games}>
					{games.map(game => (
						<div className={highscoreStyles.game}>
							<Link to={`/highscore/${game}`} key={game}>
								<div
									className={classNames(highscoreStyles.innerGame, {
										[highscoreStyles.selected]: selectedGame === game,
									})}
									style={{ backgroundImage: `url('/images/minigames/${game}.png')` }}>
									{game}
								</div>
							</Link>
						</div>
					))}
				</div>

				<div style={{ display: 'flex', justifyContent: 'space-between', gap: '20px' }}>
					<HighscorePanel interval={HighscoreInterval.Daily} game={selectedGame} />
					<HighscorePanel interval={HighscoreInterval.Weekly} game={selectedGame} />
					<HighscorePanel interval={HighscoreInterval.AllTime} game={selectedGame} />
				</div>
			</div>
		</div>
	);
};
